export class Job {

    public company = "";
    public companyLogo = "";
    public companyDescription = "";
    public title = "";
    public location = "";
    public country = "";
    public contractType = "";
    public sectors: string[] = [];
    public description = "";
    public benefits = "";
    public requirements = "";
    public experience = "";

    public uid = "";
    public profile = "";

    constructor(fields?: Partial<Job>) {
      if (fields) {
        Object.assign(this, fields);
      }
    }

}
