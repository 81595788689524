<div class="top-bg">
</div>
<div class="container">
  <div class="job-header">
    <div class="company-logo">
      <img src="{{assetsUrl}}/{{job.companyLogo}}" />
    </div>
    <div class="job-description">
      <div class="job-title">{{job.title}}</div>
      <div class="job-location" *ngIf="job.location">
        <mat-icon>place</mat-icon>
        <span>{{job.location}}</span><span *ngIf="job.country">, {{job.country}}</span>
      </div>
      <div class="job-experience" *ngIf="job.experience">
        <mat-icon>work_history</mat-icon>
        {{'Experience' | translate}} {{job.experience}}
      </div>
      <div class="job-sector">
        <div class="contract-type">
          {{job.contractType | translate}}
        </div>
        <div *ngIf="job.sectors.length > 0" style="margin-left: 5px;">
          {{'in the area' | translate}}
          <span *ngFor="let sector of job.sectors; let index = index">
            <span *ngIf="index > 0">, </span>
            <span  style="word-break: break-word;"> {{ sector }} &nbsp;</span> 
          </span> 
        </div>
      </div>
    </div>
  </div>
  <div  class="job-buttons-responsive">
    <button mat-raised-button style="    width: 85%;" color="accent" (click)="apply()">{{'cooptForThisOffer' | translate}}</button>
  </div>
  <div style="width: 100%;" class="job-content">
    <div class="job-details-container">
      <div class="job-details">
        <h2>{{'The offer' | translate}}</h2>
        <div [innerHTML]="job.description">
        </div>
      </div>
      <div class="job-details" *ngIf="job.requirements">
        <h2>{{'Requirements' | translate}}</h2>
        <div [innerHTML]="job.requirements">
        </div>
      </div>
      <div class="footer">
        <button mat-raised-button color="accent" (click)="apply()">{{'cooptForThisOffer' | translate}}</button>
        <button mat-stroked-button color="accent" (click)="viewAllOffers()">{{'View All Offers' | translate}}</button>
      </div>
    </div>

    <app-share-offer>

    </app-share-offer>
  </div>

</div>