import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { JobSearchService } from '../../services/job-search.service';
import { BrandingService } from '../../services/branding.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-job-search-page',
  templateUrl: './job-search-page.component.html',
  styleUrls: ['./job-search-page.component.scss'],
  standalone: false
})
export class JobSearchPageComponent implements OnInit {
  openedViaDialog: boolean = false;
  constructor(
    public service: JobSearchService,
    private router: Router,
    private translateService: TranslationsService,
    private branding: BrandingService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data && this.data.openedViaDialog) {
      this.openedViaDialog = true;
    }
  }

  ngOnInit(): void {
    document.title = this.translateService.translate('title_tab');
    let nav = this.router.getCurrentNavigation();
    if (!nav) {
      nav = this.router['lastSuccessfulNavigation'];
    }
    let uid = undefined;
    if (nav && nav.extras && nav.extras.state && nav.extras.state['fromJobId']) {
      uid = nav.extras.state['fromJobId'];
    }
    window.scrollTo(0, 0);
    if (uid) {
      this.scrollTo(uid, 0);
    }
  }

  private scrollTo(jobId: string, trial: number) {
    let element: any = document.getElementById('job_uid_' + jobId);
    if (element) {
      let e2 = element.previousSibling;
      if (e2) {
        element = e2;
      }
      element.scrollIntoView();
    } else if (trial < 10) {
      setTimeout(() => {
        this.scrollTo(jobId, trial + 1);
      }, 50);
    }
  }

  public loadMore(): void {
    this.service.loadMoreJobs();
  }

}
