<app-job-detail *ngIf="job" [job]="job" [isApply]="isApply"></app-job-detail>
<div class="container" *ngIf="!job">
  <div *ngIf="job === undefined" class="loading">
    <app-loading size="small"></app-loading>
  </div>
  <div *ngIf="job === null" class="not-found-message">
    <mat-icon>error_outline</mat-icon>
    {{'JOB NOT FOUND' | translate}}
    <button mat-stroked-button color="primary" (click)="viewAllOffers()">{{'View All Offers' | translate}}</button>
  </div>
</div>
