import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Agency } from "../data/agency";
import { Job } from "../data/job";
import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class SharedMethods {


    constructor(private _snackBar: MatSnackBar) { }


    // Example shared method
    public static showSnackBar(snackBar: MatSnackBar, message: string, action: string = 'OK', duration: number = 5000) {
        snackBar.open(message, action, {
            duration: duration,
        });
    }




    public createCooptationFormData(accountInfo: AccountInfo, f: FormGroup, selectedFile: File | null, selectedAgency: Agency | null, offerOption: boolean, job: Job | null): FormData {

        const formData = new FormData();
        Object.keys(f.value).forEach(key => {
            if (key !== 'consent')
                formData.append(key, f.get(key)?.value);
        });
        if (selectedFile)
            formData.append('cv_path', selectedFile);
        if (job)
            formData.append('id_offre', job.uid);
        if (selectedAgency && !offerOption)
            formData.append('agency', selectedAgency.name);
        formData.append('id_cooptant', accountInfo.localAccountId);
        if (accountInfo.name)
            formData.append('cooptant_name', accountInfo.name);
        if (accountInfo.username)
            formData.append('cooptant_email', accountInfo.username);
        return formData
    }


    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }


    private titleSubject = new BehaviorSubject<string>('Default Title');
    currentTitle$ = this.titleSubject.asObservable();
  
    public updateTitle(newTitle: string) {
      this.titleSubject.next(newTitle);
    }


}