import { Component, OnInit } from '@angular/core';
import { BrandingService } from '../../services/branding.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  standalone: false

})
export class FAQComponent implements OnInit {
  constructor(

    private branding: BrandingService,
    private translateService: TranslationsService
  ) { }

  ngOnInit(): void {
    document.title = this.translateService.translate('title_tab');

  }
}
