import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {  InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  standalone: false

})
export class LoginPageComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public siteLink: string = '';  // To store API URL
  public assetsUrl = environment.assetsUrl;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.siteLink = environment.siteLink;
    // Check if the user is already logged in
    if (this.authService.instance.getActiveAccount() !== null) {
      this.router.navigate(['/job-search']);
    }

    // Subscribe to inProgress$ to handle interaction status
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // Check if the user is logged in after interaction is complete
        if (this.authService.instance.getActiveAccount() !== null) {
          this.router.navigate(['/job-search']);
        }
      });
  }

  login() {
      window.location.href = this.siteLink;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}