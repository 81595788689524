import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AccessTokenResponse } from '../data/access-token-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private endpointOrigin: string = environment.endpointOrigin;
  private accountInfoSubject = new BehaviorSubject<AccountInfo | null>(null);
  accountInfo$ = this.accountInfoSubject.asObservable();
  initialsKey = 'initials';

  public constructor(private http: HttpClient) {}

  public authenticate(payload: {
    account: AccountInfo;
    accessToken: string;
  }): Observable<AccessTokenResponse> {
    const body = {
      email: payload.account.username,
      token: payload.accessToken,
      type: 'microsoft',
    };

    return this.http.post<AccessTokenResponse>(this.endpointOrigin + 'api/login', body);
  }

  setAccountInfo(accontInfo: AccountInfo) {
    this.accountInfoSubject.next(accontInfo);
  }

  public getCandidateInitials() {
    return localStorage.getItem(this.initialsKey);
  }

  public storeCandidateInitials(initials: string | undefined) {
    if (initials)
      localStorage.setItem(this.initialsKey, this.getInitials(initials));
    else localStorage.setItem(this.initialsKey, '');
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('');
  }
}
