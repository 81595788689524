import { Component } from '@angular/core';

@Component({
  selector: 'app-reward-section',
  templateUrl: './reward-section.component.html',
  styleUrl: './reward-section.component.scss',
  standalone: false
})
export class RewardSectionComponent {

}
