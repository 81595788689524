<!-- <div class="page-footer" [ngSwitch]="branding.brand.name" [style.background-image]="'url(' + assetsUrl + '/footer-element/footer.png)'"> -->
<div class="page-footer" [ngSwitch]="branding.brand.name">
  <ng-container *ngSwitchCase="'abylsen'">
    <div class="footer-section"> 
      <div class="footer-section-left">
        <a href="https://www.houseofhr.com/" target="_blank">
          <!-- <img src="{{assetsUrl}}/footer-element/House_of_HR.png"> -->
          <img src="{{assetsUrl}}/footer-element/MonoHOH.svg">
        </a>
        <div>
          <a href="https://www.houseofhr.com/" style="color: white; text-decoration: none;">DÉCOUVRIR HOUSE OF HR</a>
        </div>
        <div class="legal">
          <div class="copyright">
            <!-- HOUSE-OF-ABY – {{thisYear}} – {{'All rights reserved' | translate}} -->
            ⓒ HOUSE OF ABY, copywrite {{thisYear}}
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section"> 
      <div class="footer-section-middle">
        <img class="footer-section-middle-img" src="{{assetsUrl}}/footer-element/logo_pink_HOABY.png">
        <div class="legal" style="margin-top: 100px;" >
          <div class="legal-and-privacy" style="width: 100%">
            <a [href]="legalLink" target="_blank"> {{'Legal' | translate}} </a>
            <a [href]="privacyPolicyLink" target="_blank"> {{'Privacy policy' | translate}} </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section"> 
      <div class="social">
        <div> {{'FOLLOW OUR ADVENTURES' | translate}} </div>
        <div class="social-icons">
          <a href="https://www.instagram.com/abylsengroup/" target="_blank">
            <img src="{{assetsUrl}}/instagram-white-24.png">
          </a>
          <a href="https://www.linkedin.com/company/abylsen/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-white-24.png">
          </a>
          <a href="https://www.youtube.com/channel/UCDHCxUSxCch2xpcp8BVmhmg" target="_blank">
            <img src="{{assetsUrl}}/youtube-white-24.png">
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
