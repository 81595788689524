<div class="top-bg">
</div>
<div class="container">

  <div class="introduction"> 
    <span>
      {{ 'offers introduction' | translate }}
    </span>
  </div>

  <app-job-search-criteria></app-job-search-criteria>

  <div class="results-and-filters">
    <div class="filters">
      <app-job-filters></app-job-filters>
    </div>
    <div class="results">
      <div *ngIf="!service.loadingCount" class="offers-count">
        {{service.jobsCount}}
        <span *ngIf="service.jobsCount > 1">{{'available offers' | translate}}</span>
        <span *ngIf="service.jobsCount <= 1">{{'available offer' | translate}}</span>
      </div>

      <app-job-list [jobs]="service.jobs$.value" [openedViaDialog]="openedViaDialog"></app-job-list>

      <div *ngIf="service.loadingJobs" class="loading">
        <app-loading size="small"></app-loading>
      </div>
      <div *ngIf="!service.loadingJobs && service.hasMoreJobs" class="bottom-buttons">
        <button mat-raised-button color="accent" (click)="loadMore()">{{'Show more offers' | translate}}</button>
      </div>
    </div>
  </div>
</div>
