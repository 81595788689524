import { TranslationsService } from './../../services/translations.service';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrl: './faq-content.component.scss',
  standalone: false
})
export class FaqContentComponent {
  sanitizedFaqText!: SafeHtml;
  public assetsUrl = environment.assetsUrl;
  public lang = 'en';

  constructor(
    private sanitizer: DomSanitizer, 
    private translate: TranslationsService) {
    this.sanitizedFaqText = this.sanitizer.bypassSecurityTrustHtml(this.translate.translate('faq_text'));
    this.lang = this.translate.getLanguage();
    console.log(this.lang);
  }
}