import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SocialMediaService } from '../../services/social-media.service';
import { CooptationService } from '../../services/cooptation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedMethods } from '../../shared/shared-methods';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { ShareOfferPopupComponent } from './popup-share-offer/share-offer-popup.component';

@Component({
  selector: 'app-share-offer',
  templateUrl: './share-offer.component.html',
  styleUrl: './share-offer.component.scss',
  standalone: false
})

export class ShareOfferComponent implements OnInit {

  @Input() visitsNumber!: number;
  @Input() cooptationNumber!: number;

  private dlg?: MatDialogRef<any>;

  constructor(
    private router: Router,
    private socialMediaService: SocialMediaService,
    public cooptationService: CooptationService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private sharedMethod: SharedMethods,
    private authService: AuthService
  ) { }

  public ssoId!: string;

  inputText: string = ''; //Cooptation Link
  copySuccess: boolean = false;
  currentUrl!: string;
  IdOffer: string | undefined;
  NameOffer: string | undefined;

  PDFLink!: string;

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.PDFLink = environment.pdfLink

    this.authService.accountInfo$.subscribe(accountInfo => {
      if (this.currentUrl.includes('/job/') &&  accountInfo !== null) {
        this.IdOffer = this.currentUrl.split('/').pop();
        this.socialMediaService.getShareLink(accountInfo,this.IdOffer)
          .subscribe((res: any) => {
            this.inputText = res.shortLink;
          });
      } else if (accountInfo !== null) {
        this.socialMediaService.getShareLink(accountInfo).subscribe((res: { shortLink: string }) => {
          this.inputText = res.shortLink;
        });
      }
    });
  }

  copyToClipboard() {
    const inputElement = document.createElement('textarea');
    inputElement.value = this.inputText;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
    setTimeout(() => {
      this.dialog.closeAll();
      this.sharedMethod.openSnackBar('Texte copié !', "OK");
    }, 0);
  }

  shareOffer(socialMedia: string, jobURL: string) {
    if(socialMedia == 'linkedin' || socialMedia == 'facebook') {
      jobURL ? this.shareOfferPopup(socialMedia, jobURL) : console.log('error wrong URL');
    } else {
      jobURL ? this.socialMediaService.share(socialMedia, jobURL, 'job') : console.log('error wrong URL');
    }
  }

  shareOfferPopup(socialMedia: string, jobURL: string) {
    this.dlg = this.dialog.open(ShareOfferPopupComponent, {
      data: { socialMedia: socialMedia, jobUrl: jobURL } // Passez un objet contenant jobURL
    });
    this.dlg.afterClosed().subscribe(() => {
      this.dlg = undefined;
      jobURL ? this.socialMediaService.share(socialMedia, jobURL, 'job') : console.log('error wrong URL');
    });
  }
}
