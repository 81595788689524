import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subscription, tap } from 'rxjs';
import { Job } from '../data/job';
import { JobSearchCriteria } from '../data/job-search-criteria';
import { BrandingService } from './branding.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobSearchService {
  private apiURLorigin: string = environment.apiUrl;
  private nbJobsPerRequest = 25;
  private brands: string[] = [];

  public jobs$ = new BehaviorSubject<Job[]>([]);
  public loadingJobs?: Subscription = undefined;
  public hasMoreJobs = false;
  public latestPageLoaded = -1;
  public loadingCount?: Subscription = undefined;
  public jobsCount = 0;
  public typesOfContract$: BehaviorSubject<Map<string, number>> = new BehaviorSubject(new Map());
  public sectors$: BehaviorSubject<Map<string, number>> = new BehaviorSubject(new Map());
  public countries$: BehaviorSubject<Map<string, number>> = new BehaviorSubject(new Map());
  public locations$: BehaviorSubject<Map<string, number>> = new BehaviorSubject(new Map());
  public loadingCounts?: Subscription = undefined;

  public criteria = new JobSearchCriteria();
  public lg = 'fr';

  constructor(
    private http: HttpClient,
    private branding: BrandingService
  ) {
  }

  public resetJobs(): void {
    this.jobs$.next([]);
    this.latestPageLoaded = -1;
    this.loadMoreJobs();
  }

  public loadMoreJobs(): void {
    if (this.loadingJobs) {
      this.loadingJobs.unsubscribe();
      this.loadingJobs = undefined;
    }
    const loadCounts = this.latestPageLoaded < 0;
    if (loadCounts) {
      if (this.loadingCount) {
        this.loadingCount.unsubscribe();
      }
      this.loadingCount = this.countJobs().subscribe(count => {
        if (this.loadingCount) {
          this.jobsCount = count;
          this.loadingCount = undefined;
        }
      });
    }
    this.hasMoreJobs = false;
    this.latestPageLoaded++;
    this.loadingJobs =
      this.searchJobs(this.latestPageLoaded * this.nbJobsPerRequest, this.nbJobsPerRequest)
        .subscribe(jobs => this.newJobsLoaded(jobs));
    if (loadCounts) {
      if (this.loadingCounts) {
        this.loadingCounts.unsubscribe();
      }
      this.loadingCounts = this.counts().subscribe(result => {
        this.loadingCounts = undefined;
        let map = new Map();
        for (let type in result["typesOfContract"]) {
          map.set(type, result["typesOfContract"][type]);
        }
        this.typesOfContract$.next(map);
        map = new Map();
        for (let type in result["sectors"]) {
          map.set(type, result["sectors"][type]);
        }
        this.sectors$.next(map);
        map = new Map();
        for (let type in result["countries"]) {
          map.set(type, result["countries"][type]);
        }
        this.countries$.next(map);
        map = new Map();
        for (let type in result["locations"]) {
          map.set(type, result["locations"][type]);
        }
        this.locations$.next(map);
      });
    }
  }

  private newJobsLoaded(list: Job[]): void {
    if (this.loadingJobs) {
      const jobs = this.jobs$.value.concat(list);
      this.jobs$.next(jobs);
      this.loadingJobs.unsubscribe();
      this.loadingJobs = undefined;
      this.hasMoreJobs = list.length == this.nbJobsPerRequest;
    }
  }

  private updateCriteria(): void {
    this.criteria.companies = this.brands;
  }

  public setBrands(brands: string[]){
    this.brands = brands;
  }

  public searchJobs(first: number, nb: number): Observable<Job[]> {

    const apiUrl = `${this.apiURLorigin}/jobs/search?from=${first}&nb=${nb}`;
    this.updateCriteria();
    const requestBody = {
      ...this.criteria
    };
    return this.http.post<any>(apiUrl, requestBody).pipe(
      map(response => {
        if (Array.isArray(response)) {
          return (response as Job[]).map(job => this.completeJob(new Job(job))!);
        } else {
          console.error('Expected an array of jobs, but received:', response);
          return [];
        }
      })
    );
  }



  public countJobs(): Observable<number> {
    const apiUrl = `${this.apiURLorigin}/jobs/search`;

    // Update the criteria before making the request
    this.updateCriteria();

    // Make a POST request to get the jobs, then count the number of jobs by getting the length of the response array
    return this.http.post<any>(apiUrl, this.criteria).pipe(
      map(response => {
        if (Array.isArray(response)) {
          return response.length; // Return the length of the jobs array (count of jobs)
        } else {
          console.error('Expected an array of jobs, but received:', response);
          return 0; // Default to 0 if the response isn't an array
        }
      })
    );
  }


  public counts(): Observable<{ [filter: string]: { [k: string]: number } }> {
    this.updateCriteria();
    return this.http.get<{ [filter: string]: { [k: string]: number } }>(`${this.apiURLorigin}/jobs/filters/count`);
  }

  public getJobByUid(uid: string): Observable<Job | null> {
    // look into known jobs
    for (let job of this.jobs$.value) {
      if (job.uid == uid) {
        return of(job);
      }
    }
    return this.http.get<Job>(`${this.apiURLorigin}/jobs/detail/${uid}`).pipe(
      map(job => job ? this.completeJob(new Job(job)) : null)
    );
  }

  private completeJob(job: Job): Job | null {
    if (!job.company || job.company.trim().length == 0) {
      job.company = this.branding.brand.displayName;
    } else {
      if (!this.branding.brand.isMultiBranding && job.company !== this.branding.brand.displayName) {
        return null;
      }
    }
    job.companyLogo = "logo-150x150-" + job.company.toLowerCase() + ".png";
    return job;
  }

}
