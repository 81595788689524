import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the token from cookies
    const token: string | null = this.cookieService.get('accessToken') || null;


    let clonedRequest = req;

    // Set Authorization header if the token exists
    if (token) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    const language = localStorage.getItem('lang') || 'fr';

    // Set the Accept-Language header
    clonedRequest = clonedRequest.clone({
      setHeaders: {
        'locale': language,
      },
    });

    return next.handle(clonedRequest);
  }
}
