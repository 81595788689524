import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AccountInfo,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { AuthService } from './services/auth.service';
import { AccessTokenResponse } from './data/access-token-response';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  isLoginPage: boolean = false;

  constructor(
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private msalService: MsalService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    // Handle route changes to determine if the current page is the login page
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isLoginPage = this.router.url === '/login';
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe({
        next: (result: EventMessage) => {
          const account: AccountInfo = (result.payload as any).account;

          const payload = result.payload as { account: AccountInfo; accessToken: string };

          this.authService.setAccountInfo(account);
          console.log('account', account);
          this.authService.storeCandidateInitials(account.name);

          // Use the access token to authenticate
          this.authService.authenticate(payload).subscribe({
            next: (accessTokenResponse: AccessTokenResponse) => {
              this.cookieService.set('accessToken', accessTokenResponse.token, undefined, '/');
            },
            error: (error) => {
              console.error('Authentication failed:', error);
            },
          });
        },
        error: (error) => {
          console.error('Error in msalSubject subscription:', error);
        },
      });

    this.checkAccount();
  }

  private checkAccount() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];

      this.authService.setAccountInfo(account);
      this.authService.storeCandidateInitials(account.name);
    }
  }
}
