import { Cooptation } from './../../data/cooptation';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobSearchService } from '../../services/job-search.service';
import { DialogService } from '../../services/dialog.service';
import { CooptationService } from '../../services/cooptation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-cooptation-card',
  templateUrl: './cooptation-card.component.html',
  styleUrl: './cooptation-card.component.scss',
  standalone: false
})
export class CooptationCardComponent implements OnInit {
  @Input() currentState: number = 0; // State from 0 to 5
  @Input() rating: number = 0; // Rating from 1 to 3
  @Input() cooptation!: Cooptation;
  @Output() relanceEvent = new EventEmitter<Cooptation>();

  public byLink = 'Via lien'
  public byForm = 'Via formulaire'

  public steps!: { label: string; icon?: string; }[];
  constructor(

    public service: JobSearchService,
    public dialogService: DialogService,
  ) {

  }

  ngOnInit(): void {

    if (this.cooptation.state >= 0) {
      this.steps = [
        { label: 'Pending validation', icon: 'history' },
        { label: 'Interview in progress' },
        { label: 'Offer made' },
        { label: 'Application result' },
        { label: 'Candidate in reserve', icon: 'person_check' },
      ];
    }
    else {
      this.steps = [
        { label: 'Pending validation', icon: 'history' },
        { label: 'Interview in progress' },
        { label: 'Offer made' },
        { label: 'Application result' },
        { label: 'Application not retained', icon: 'person_off' }
      ];

    }
  }


  public relance(coopt: Cooptation) {
    this.relanceEvent.emit(coopt);
  }



  getRecLevel(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.recommandation_level
    } else {
      return 1
    }
  }


  getState(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.state
    } else {
      return 0
    }
  }

  isDateOlderThan24Hours(updatedAt: Date | undefined): boolean {
    if (updatedAt) {
      const updatedDate = new Date(updatedAt).getTime();
      const currentDate = new Date().getTime();
      const diffInHours = (currentDate - updatedDate) / (1000 * 60 * 60);
      return diffInHours > 24;
    }
    else
      return false
  }

  isStepIconBordered(stepIndex: number): boolean {
    return stepIndex >= 1 && stepIndex <= 3;
  }

  getStepIconColor(stepIndex: number): string {
    return stepIndex <= this.getState(this.cooptation) ? '#a5e71c' : '#ddd';
  }

  getStepIconBorderColor(stepIndex: number): string {
    const currentState = this.getState(this.cooptation);
    return stepIndex === currentState && this.isStepIconBordered(stepIndex) ? '#a5e71c' : '#ddd';
  }

  hasError(): boolean {
    return this.getState(this.cooptation) < 0;
  }


}
