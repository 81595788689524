export const environment = {
  production: true,
  debugMode: false,
  assetsUrl: '/assets',
  apiUrl: 'https://jobs.abylsen.com/backendLaravel/public/api/v1/cooptations',
  endpointOrigin: 'https://jobs.abylsen.com/backendLaravel/public/',
  pdfLink: 'https://jobs.abylsen.com/backendLaravel/public/api/v1/cooptations/pdf-link',
  siteLink:'https://cooptation.houseofaby.com/',  
  spontaneousApplicationForm: 'resource/cxsrec__cxsForm',
  linkedInClientId: '78qhafo7x8cbqh',
  clientId: '3ab1211b-cdce-425f-9943-abcb96659ef7',
};
