<div class="recommendation-card">
  <div class="header-card">
    <div>
      <button *ngIf="cooptation.origin===byLink" mat-raised-button [disabled]="true" class="custom-button"
        style="margin-top: -65px;">
        <mat-icon>link</mat-icon>
        {{'by link' | translate}}
      </button>

      <button *ngIf="cooptation.origin===byForm" mat-raised-button [disabled]="true" class="custom-button"
        style="margin-top: -65px;">
        <mat-icon>account_circle</mat-icon>
        {{'by form' | translate}} </button>
    </div>
    <div>
      <button mat-button class="relancer-btn"   [disabled]="!isDateOlderThan24Hours(cooptation.updated_at) || getState(cooptation) <0"  (click)="relance(cooptation)">Relancer</button>
    </div>
  </div>

  <div class="content-card">
    <h2> {{cooptation.coopte?.firstname+' '+cooptation.coopte?.lastname}}</h2>
    <a [routerLink]="['/job', cooptation.id_offre]">
      <span>
        {{cooptation.offre?.title}}
      </span>
    </a>

    <span *ngIf="!cooptation?.offre?.title"> {{'no offer selected' | translate}}
    </span>

    <blockquote>
      {{cooptation.description}}
    </blockquote>

    <div class="stars">
      <i *ngFor="let star of [1, 2, 3]" class="star" [ngClass]="{'active': getRecLevel(cooptation) >= star}">
        &#9733;
      </i>
      <span>&nbsp;
        <ng-container *ngIf="cooptation?.recommandation_level == 1"> {{'slightly Recommended' |
          translate}}</ng-container>
        <ng-container *ngIf="cooptation?.recommandation_level == 2"> {{'recommended' | translate}}</ng-container>
        <ng-container *ngIf="cooptation?.recommandation_level == 3"> {{'strongly Recommended' |
          translate}}</ng-container>
      </span>
    </div>

    <small>{{'sent at' | translate}} : {{cooptation.created_at | date}}</small>
    <mat-divider></mat-divider>
    <div class="pipeline">
      <div *ngFor="let step of steps; let i = index" class="step-container">

        <div class="step" [ngClass]="{ 'error': (getState(cooptation) < 0 )}">
          <i class="step-icon" [ngClass]="{'bordered': i === 1 || i === 2|| i === 3}" [ngStyle]="{ 
          'color': ( i<= getState(cooptation)) ? '#D31876' : 'defaultColor',
          'border-color': (i === getState(cooptation) && (i === 1 || i === 2 || i === 3)) ? '#D31876' : 'initial'
          }">
            <mat-icon style="zoom: 2.2;">{{ step.icon }}</mat-icon>
          </i>
          <span class="step-text">{{ step.label | translate}}</span>
        </div>
        <span *ngIf="i < steps.length - 1 && i < 4" class="separator">___</span>
      </div>
    </div>