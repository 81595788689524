<div class="container">
  <!-- <div class="logo" [ngSwitch]="branding.brand.name">
    <a *ngSwitchCase="'abylsen'" href="https://www.abylsen.com" target="_blank">
      <img src="{{assetsUrl}}/logo/Vector.svg" />
    </a>
  </div> -->

  <div class="menu">
    <div class="menu-up">
      <div class="menu-logo">
        <a href="https://www.houseofhr.com" target="_blank">
          <img src="{{assetsUrl}}/logo/Vector.png" />
        </a>
      </div>
      <div class="menu-text">
        <p [innerHTML]="'welcome' | translate"></p>
      </div>
      <div class="menu-galerie" style="width:50%">
        <img src="{{ assetsUrl }}/logo-svg/ABYLSEN.svg">
        <!-- <img src="{{ assetsUrl }}/logo-svg/BELGATECH.svg"> -->
        <img src="{{ assetsUrl }}/logo-svg/ABMI.svg">
        <img src="{{ assetsUrl }}/logo-svg/GREENWORKING.svg">
        <img src="{{ assetsUrl }}/logo-svg/OPENIUM.svg">
        <img src="{{ assetsUrl }}/logo-svg/RTONE.svg">
        <img src="{{ assetsUrl }}/logo-svg/PI.svg">
        <img src="{{ assetsUrl }}/logo-svg/PHARMACOS.svg">
        <!-- <img src="{{ assetsUrl }}/logo-svg/MYKELSON.svg"> -->
        <img src="{{ assetsUrl }}/logo-svg/LAHOCY.svg">
        <img src="{{ assetsUrl }}/logo-svg/TEC.svg">
        <!-- <img src="{{ assetsUrl }}/logo-svg/EASTELSON.svg"> -->
      </div>

      <div class="menu-profile">
        <div class="languages">
          <img src="{{ assetsUrl }}/en.png" (click)="setLanguage('en')" />
          <img src="{{ assetsUrl }}/fr.png" (click)="setLanguage('fr')" />
        </div>
      
        <div class="profile-icon" [ngSwitch]="branding.brand.name" [matMenuTriggerFor]="menu">
          <div *ngIf="isAuthenticated" class="logged-in">
            <div class="circle">{{ authService.getCandidateInitials() }}</div>
          </div>
          <mat-menu #menu="matMenu" class="custom-menu">
            <button *ngIf="isAuthenticated" class="menu-item" (click)="logout()" mat-menu-item>
              {{ "Log out" | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="pink-line"></div>
  
    <div class="menu-down">
      <nav mat-tab-nav-bar color="primary" mat-align-tabs="center" #tabs  [tabPanel]="tabPanel">
        <a mat-tab-link routerLink="/" routerLinkActive [active]="isOffersActive()" queryParamsHandling="preserve">
          {{ "Offers" | translate | uppercase }}
        </a>
        <a mat-tab-link routerLink="/my-cooptation" routerLinkActive [active]="myCoopt()">
          {{ "my cooptations" | translate | uppercase }}
        </a>
        <a mat-tab-link routerLink="/faq" routerLinkActive [active]="faq()">
          {{ "faq" | translate | uppercase }}
        </a>
      </nav>
    </div>
  </div>
  <mat-tab-nav-panel #tabPanel> </mat-tab-nav-panel>

</div>