<div class="faq-content">
  <h1> FAQ </h1>
  <p [innerHTML]="'faq_text_intro' | translate"></p>
  <p [innerHTML]="'faq_modalite_title' | translate"></p>
  <p [innerHTML]="'faq_modalite_who' | translate"></p>

  <img *ngIf="lang == 'fr'" class="board-coopt" src="{{ assetsUrl }}/faq-board/board-coopt-FR.svg" />
  <img *ngIf="lang == 'en'" class="board-coopt" src="{{ assetsUrl }}/faq-board/board-coopt-EN.svg" />

  <p [innerHTML]="'faq_modalite_condition' | translate"></p>

  <p [innerHTML]="'faq_modalite_profile' | translate"></p>

  <img *ngIf="lang == 'fr'" class="board-profile-coopt" src="{{ assetsUrl }}/faq-board/board-coopt-profile-FR.svg"/>
  <img *ngIf="lang == 'en'" class="board-profile-coopt" src="{{ assetsUrl }}/faq-board/board-coopt-profile-EN.svg"/>

  <p [innerHTML]="'faq_modalite_profile_nb' | translate"></p>

  <p [innerHTML]="'faq_prime' | translate"></p>
  <p [innerHTML]="'faq_prime_modalite' | translate"></p>
  <p [innerHTML]="'faq_when' | translate"></p>

</div>