<div class="side-elements">
  <div class="copy-container">
    <h2 class="share-label">{{ "share" | translate }}</h2>

    <mat-form-field class="custom-form-field" appearance="outline">
      <input
        (click)="copyToClipboard()"
        matInput
        type="text"
        [(ngModel)]="inputText"
      />
      <mat-icon (click)="copyToClipboard()" class="icon-align" matSuffix
        >link</mat-icon
      >
    </mat-form-field>

    <div class="share-it">
      <div class="share-options">
        <div class="icon-wrapper" (click)="shareOffer('mail', inputText)">
          <img src="./assets/icons/mail-black.svg" alt="mail" />
        </div>
        <div class="icon-wrapper" (click)="shareOffer('linkedin', inputText)">
          <img src="./assets/icons/linkedin-black.svg" alt="LinkedIn" />
        </div>
        <div class="icon-wrapper" (click)="shareOffer('twitter', inputText)">
          <img src="./assets/icons/twitter-black.svg" alt="Twitter" />
        </div>
        <div class="icon-wrapper" (click)="shareOffer('facebook', inputText)">
          <img src="./assets/icons/facebook-black.svg" alt="Facebook" />
        </div>
      </div>
    </div>
  </div>


  <div>  <app-reward-section></app-reward-section>
  </div>

  <div
    class="stats-container stats-label"
    *ngIf="visitsNumber != undefined && cooptationNumber != undefined"
  >
    <h2 class="stats-header">{{ "my statistics" | translate }}</h2>
    <div class="stats-row">
      <div class="stat">
        <p class="stat-number">{{ visitsNumber }}</p>
        <p class="stat-label">
          {{ "people whom" | translate }}<br />{{
            "clicked my link" | translate
          }}
        </p>
      </div>
      <div class="stat">
        <p class="stat-number">{{ cooptationNumber }}</p>
        <p class="stat-label">{{ "coopted candidats" | translate }}</p>
      </div>
    </div>
  </div>
  <!-- <div class="help-container">
    <h2 class="help-title">{{ "need help" | translate }}</h2>
    <div class="rules-link">
      <a class="text-custom" [href]="PDFLink" target="_blank">
        {{ "consult" | translate }} {{ "the referral rules" | translate }}
      </a>
      <mat-icon >find_in_page</mat-icon>
    </div>
  </div> -->
</div>
