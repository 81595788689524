import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../services/translations.service';
import { BrandingService } from '../../services/branding.service';
import { environment } from '../../../environments/environment';
 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: false
})
export class FooterComponent implements OnInit {

  public assetsUrl = environment.assetsUrl;
  public thisYear;
  public legalLink;
  public privacyPolicyLink;

  constructor(translations: TranslationsService, public branding: BrandingService) {
    this.thisYear = new Date().getFullYear();
    this.legalLink = translations.translate('legal-link');
    this.privacyPolicyLink = translations.translate('privacy-policy-link');
  }

  ngOnInit(): void {

  }

}
