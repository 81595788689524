<div class="login-page">
  <div class="login-image">
    <img class="bg" src="{{assetsUrl}}/logo/center content.svg" alt="" />
  </div>
  
  <div class="line" ></div> <!-- This is the white line -->
  
  <div class="login-form">
    <div class="form-content">
      <h2>{{ "READY_TO_EXPLORE" | translate }}</h2>
      <div class="sso-options">
        <button class="colored-button" (click)="login()"> {{ "SSO_connexion" | translate }} </button>
      </div>
    </div>
    <div class="login-image-phone">
      <img class="bg" src="{{assetsUrl}}/logo/center content.svg" alt="" />
    </div>
  </div>
</div>