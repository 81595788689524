import { Component, OnInit } from '@angular/core';
import { CooptationService } from '../../services/cooptation.service';
import { Cooptation } from '../../data/cooptation';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { BrandingService } from '../../services/branding.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-my-cooptation',
  templateUrl: './my-cooptation.component.html',
  styleUrl: './my-cooptation.component.scss',
  standalone: false
})
export class MyCooptationComponent implements OnInit {

  public cooptationsList!: Cooptation[];

  public visitsNumber: number = 0;
  public cooptationNumber: number = 0;
  public ssoId!: string;

  constructor(
    public cooptationService: CooptationService,
    private authService: AuthService,
    private branding: BrandingService,
    private translateService: TranslationsService

  ) {
  }


  ngOnInit(): void {
    document.title = this.translateService.translate('title_tab');
    this.authService.accountInfo$.subscribe(accountInfo => {
      if (accountInfo !== null) {
        this.ssoId = accountInfo.localAccountId
        this.getCooptations(this.ssoId);
        this.getVisitsNumber(this.ssoId);
      }

    });
  }


  getCooptations(ssoId: string) {
    this.cooptationService.GetCooptationsByUserId(ssoId).subscribe({
      next: (cooptations: Cooptation[]) => {
        this.cooptationsList = cooptations;
        this.cooptationNumber = cooptations.length;
      },
      error: (err) => {
        this.cooptationsList = [];  // Reset or set to an empty list
        this.cooptationNumber = 0;  // Reset the cooptation count
      }
    });
  }

  getVisitsNumber(ssoId: string) {
    this.cooptationService.getVisitsNumber(ssoId).subscribe({
      next: (tv: { total_visits: number }) => {
        this.visitsNumber = tv.total_visits;
      },
      error: (err) => {
        this.visitsNumber = 0;  // Reset visits number
      }
    });
  }

}
