<div class="company-logo" *ngIf="showLogo">
  <img width="75px" height="75px" src="{{environment.assetsUrl}}/{{job.companyLogo}}"/>
  <div class="contract-type">
    {{job.contractType | translate}}
  </div>
</div>
<div class="job-description">
  <div class="job-title">{{job.title}}</div>
  <div class="job-location" *ngIf="job.location">
    <mat-icon>place</mat-icon>
    <span>{{job.location}}</span><span *ngIf="job.country">, {{job.country}}</span>
  </div>
  <div class="job-sector" *ngIf="job.sectors.length > 0">
    <mat-icon>domain</mat-icon>
    <div>
      <span *ngFor="let sector of job.sectors; let index = index">
        <span *ngIf="index > 0">, </span>
        <span>{{sector}}</span>
      </span>
    </div>
  </div>
</div>
<div class="contract-type" *ngIf="showContractType">
  {{job.contractType | translate}}
</div>
