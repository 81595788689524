<h1>{{ "my cooptations" | translate }}</h1>

<div *ngIf=" cooptationsList && cooptationsList.length === 0" class="empty-state-container">
  <img src="/assets/image-no-cooptation-white.png" class="empty-state-image">
  <p class="empty-state-text">
    {{'coopt_friends' | translate}}
  </p>
  <button mat-raised-button color="accent" (click)="coopt()" class="empty-state-button">
    <mat-icon>person_add</mat-icon>
    {{ 'coopt candidate' | translate | uppercase }}
  </button>
</div>


<div class="padding-16" *ngIf="cooptationsList && cooptationsList.length > 0">
  <button class="padding-20-40" mat-raised-button color="accent" (click)="coopt()">
    <mat-icon>person_add</mat-icon>
    {{'coopt candidate' | translate | uppercase}}</button>
</div>
<div *ngIf="cooptationsList && cooptationsList.length > 0">
  <div *ngFor="let cooptation of cooptationsList" style="padding:20px 0;">
    <app-cooptation-card [cooptation]="cooptation" (relanceEvent)="relance($event)">
    </app-cooptation-card>
  </div>
</div>