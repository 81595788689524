import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TranslationsService } from '../../services/translations.service';
import { BrandingService } from '../../services/branding.service';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false
})
export class HeaderComponent implements OnInit {
  public assetsUrl = environment.assetsUrl;
  public siteLink = environment.siteLink;

  @ViewChild('tabs') tabs?: MatTabNav;

  constructor(
    private translation: TranslationsService,
    private router: Router,
    public branding: BrandingService,
    private msalService: MsalService,
    public authService: AuthService,
    private cookieService: CookieService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    for (let i = 0; i < 20; i++)
      setTimeout(() => {
        this.tabs?._alignInkBarToSelectedTab();
      }, i * 100);
  }

  public setLanguage(lang: string): void {
    this.translation.setLanguage(lang);
  }

  public isOffersActive() {
    let path = this.router.url;
    let i = path.indexOf('?');
    if (i >= 0) {
      path = path.substring(0, i);
    }
    i = path.indexOf('#');
    if (i >= 0) {
      path = path.substring(0, i);
    }
    return path === '/' || path.startsWith('/job/');
  }

  public isSpontaneousActive() {
    return this.router.url == '/coopt';
  }

  public myCoopt() {
    return this.router.url == '/my-cooptation';
  }

  public faq() {
    return this.router.url == '/faq';
  }

  get isAuthenticated() {
    return true;
  }

  logout() {
    this.cookieService.delete('accessToken', '/');
    this.ngZone.run(() => {
      this.msalService.logoutRedirect({
        postLogoutRedirectUri: this.siteLink + '/login',
      });
    });
  }
}
