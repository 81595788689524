import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from './translations.service';

@Pipe({
  name: 'translate',
  pure: false,
  standalone: false
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private service: TranslationsService
  ) {}

  transform(value: string): string {
    return this.service.translate(value);
  }

}
