export class JobSearchCriteria {

  public language = '';
  public title = '';
  public typesOfContract: string[] = [];
  public sectors: string[] = [];
  public countries: string[] = [];
  public locations: string[] = [];
  public companies?: string[];

}
