<mat-form-field appearance="outline">
  <mat-label>{{'Job title or keywords' | translate}}</mat-label>
  <input matInput [(ngModel)]="title" (ngModelChange)="titleChanged()">
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
<div class="filters-button">
  <button mat-mini-fab color="accent" (click)="openFilters()">
    <mat-icon>tune</mat-icon>
  </button>
</div>
