import { Injectable } from '@angular/core';
import { JobSearchPageComponent } from '../pages/job-search-page/job-search-page.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Job } from '../data/job';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogRef: MatDialogRef<JobSearchPageComponent> | null = null;

  constructor(private dialog: MatDialog) { }

  openDialog() {
    this.dialogRef = this.dialog.open(JobSearchPageComponent, {
      width: '55%',
      height: '70%',
      data: { openedViaDialog: true }
    });
    return this.dialogRef
  }

  closeDialog(job: Job): void {
    if (this.dialogRef) {
      this.dialogRef.close(job);
      this.dialogRef = null; 
    }
  }
}
